<div
  class="modal fade"
  id="errorModal"
  tabindex="-1"
  aria-labelledby="errorModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="errorModalLabel">Ocurrió un problema</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h5>{{ errorMessage }}</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="bg-light pt-2 pb-2">
  <div class="text-center">
    <img
      src="/img/unayuda_plain.png"
      alt="logo de portal"
      style="max-height: 200px; max-width: 200px"
    />
  </div>
</div>
<section class="vh-90">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form (ngSubmit)="onSubmit()">
          <div class="divider d-flex align-items-center my-4">
            <h3 class="text-left fw-bold mx-3 mb-0">
              Ingresa tus datos de registro :
            </h3>
          </div>

          <!-- Nombres -->
          <div data-mdb-input-init class="form-outline mb-4">
            <input
              type="text"
              id="form3Example3"
              class="form-control form-control-lg"
              placeholder="Nombre"
              [(ngModel)]="nombre"
              name="nombre"
            />
            <label class="text-danger" *ngIf="mensajeNombre">{{
              mensajeNombre
            }}</label>
          </div>

          <!-- Email input -->
          <div data-mdb-input-init class="form-outline mb-4">
            <input
              type="email"
              id="form3Example3"
              class="form-control form-control-lg"
              placeholder="Correo Electrónico"
              [(ngModel)]="correo"
              name="correo"
            />
            <label class="text-danger" *ngIf="mensajeCorreo">{{
              mensajeCorreo
            }}</label>
          </div>

          <!-- Password input -->
          <div data-mdb-input-init class="form-outline mb-3">
            <input
              type="password"
              id="form3Example4"
              class="form-control form-control-lg"
              placeholder="Contraseña"
              [(ngModel)]="contrasena"
              name="contrasena"
            />
          </div>

          <!-- Repeat Password input -->
          <div data-mdb-input-init class="form-outline mb-3">
            <input
              type="password"
              id="form3Example4"
              class="form-control form-control-lg"
              placeholder="Repita Contraseña"
              [(ngModel)]="repetirContrasena"
              name="repetirContrasena"
            />
            <label class="text-danger" *ngIf="mensajeContrasena">{{
              mensajeContrasena
            }}</label>
          </div>

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              [(ngModel)]="aceptaTerminos"
              name="aceptaTerminos"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Acepto los
              <a href="/terminos" target="_blank">Términos y condiciones</a> y
              las
              <a href="/privacidad" target="_blank">Políticas de privacidad</a>
            </label>
          </div>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-primary btn-lg"
              [disabled]="!formularioValido"
              style="justify-content: center"
            >
              Crear cuenta
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<div style="padding: 50px"></div>
<!-- Footer-->
<footer class="footer bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
        <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <a href="/terminos">Términos y condiciones</a>
          </li>
          <li class="list-inline-item">⋅</li>
          <li class="list-inline-item">
            <a href="/privacidad">Política de privacidad</a>
          </li>
        </ul>
        <p class="text-muted small mb-4 mb-lg-0">
          &copy; unAyuda.com 2024. Todos los derechos reservados.
        </p>
      </div>
    </div>
  </div>
</footer>
