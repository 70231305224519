import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
@Component({
  selector: 'app-privacidad',
  standalone: true,
  imports: [HeaderComponent],
  templateUrl: './privacidad.component.html',
  styleUrl: './privacidad.component.css',
})
export class PrivacidadComponent {}
