<app-header></app-header>
<div class="container mt-5 w-50">
   <div class="row justify-content-lg-start">
      <h2 class="text-center mb-4" style="font-size:xx-large;"> Revisa los datos de tu publicación</h2>

      <form>
         <div class="row">
          <div class="col-sm">
            <div class="form-group">
               <img src={{this.publicacion.foto}} alt ="..." class="img-thumbnail rounded" width="300">
              </div> 
            </div>
            <div class="col-sm">
               <div class="mb-3">
                  <label for="formFile" class="form-label">Subir nueva imagen</label>
                  <input class="form-control" type="file" id="formFile" (change)="handleFileInput($event)">
               </div>
               <div class="form-group">
                  <label for="nombreInput">Nombre :</label>
                  <input type="text" name="nombreInput" class="form-control" [(ngModel)]="this.publicacion.nombre">
               </div>
               <div class="form-group">
                  <label for="valorInput">Valor</label>
                  <input type="text" name="valorInput" class="form-control" [(ngModel)]="this.publicacion.valor">
               </div>
            </div>
         </div>
         <div class="row mt-3 mb-3">
            <div class="col-sm">
               <div class="form-group">
                  <label for="descInput">Descripción :</label>
                  <textarea name="descInput" class="form-control"  rows="6" [(ngModel)]="this.publicacion.descripcion"></textarea>
               </div> 
            </div>
            <div class="col-sm">
               <div class="form-group">
                  <label for="fbInput">Facebook</label>
                  <input type="text" name="fbInput"  class="form-control" [(ngModel)]="this.publicacion.facebook">
               </div>
               <div class="form-group">
                  <label for="igInput">Instagram</label>
                  <input type="text" name="igInput" class="form-control" [(ngModel)]="this.publicacion.instagram">
               </div>
               <div class="form-group">
                  <label for="wtInput">Whatsapp</label>
                  <input type="text" name="wtInput" class="form-control" [(ngModel)]="this.publicacion.whatsapp">
               </div>
            </div>
         </div>
         <div class="mb-5 mt-3 text-center">
            <button type="submit" class="btn mt-1 btn-warning mx-5 btn-lg" style="min-width:200px;" (click)="submit()">Guardar</button>
            <button type="submit" class="btn mt-3 btn-outline-danger btn-sm mx-5" (click)="delete()">Eliminar mi publicación</button>
         </div>
      </form>
   </div>
</div>