<script
  sync
  defer
  crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js"
></script>
<div
  class="modal fade"
  id="errorModal2"
  tabindex="-1"
  aria-labelledby="errorModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="errorModalLabel">Ocurrió un problema</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h1>{{ errorMessage }}</h1>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="bg-light" style="padding-top: 1%; padding-left: 5%; height: 65px">
  <h1 style="font: 30px">
    <a class="navbar-brand fw-bold" style="color: black" href="/login"
      >Bienvenido</a
    >
  </h1>
</div>
<section class="vh-90">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <div class="text-center">
            <img
              src="/img/unayuda.png"
              alt="logo de portal"
              style="max-height: 200px; max-width: 200px"
            />
          </div>
          <div class="divider d-flex align-items-center my-4">
            <p class="text-left fw-bold mx-3 mb-0">Ingresa con tu cuenta :</p>
          </div>
          <div class="social-login-container">
            <button
              (click)="loginGoogle()"
              class="social-login-btn google-login-btn"
            >
              <img
                src="https://www.google.com/favicon.ico"
                alt="Google logo"
                class="social-icon"
              />
              <span class="btn-text">Iniciar sesión con Google</span>
            </button>

            <!--button (click)="loginWithFacebook()" *ngIf="facebookSdkLoaded" class="social-login-btn facebook-login-btn">
                <img src="https://www.facebook.com/favicon.ico" alt="Facebook logo" class="social-icon">
                <span class="btn-text">Iniciar sesión con Facebook</span>
              </button-->
          </div>

          <div data-mdb-input-init class="form-outline mb-4 mt-3">
            <p class="text-left fw-bold mx-3 mb-2">
              O bien con tu cuenta de unayuda.com
            </p>
            <input
              [(ngModel)]="email"
              name="email"
              type="email"
              id="emailInput"
              class="form-control form-control-lg"
              placeholder="Correo Electrónico"
            />
            <div *ngIf="!emailValido" class="text-danger">
              El correo electrónico no es válido.
            </div>
          </div>

          <div data-mdb-input-init class="form-outline mb-3">
            <input
              [(ngModel)]="contrasena"
              name="password"
              type="password"
              id="passwordInput"
              class="form-control form-control-lg"
              placeholder="Contraseña"
            />
            <div *ngIf="!passwordValida" class="text-danger">
              La contraseña debe tener al menos 6 caracteres.
            </div>
          </div>

          <div class="text-center">
            <button
              (click)="iniciarSesion()"
              class="btn btn-primary btn-lg"
              style="justify-content: center"
            >
              Iniciar sesión
            </button>
          </div>
          <div class="text-center" style="padding-top: 5%">
            <p>No tienes cuenta ? <a href="/registrar">Registrate!</a></p>
          </div>
        </form>
      </div>
      <div class="col-md-9 col-lg-6 col-xl-5">
        <section class="testimonials text-center">
          <div class="container">
            <h2 class="mb-5">¿ Que opina la gente ?</h2>
            <div class="row">
              <div class="col-lg-4">
                <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                  <img
                    style="max-height: 200px"
                    class="img-fluid rounded-circle mb-3"
                    src="/img/testimonio1.png"
                    alt="..."
                  />
                  <h5>Carla P.</h5>
                  <p class="font-weight-light mb-0">
                    "Es excelente la calidad humana para atender pacientes, muy
                    buena profesional!"
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                  <img
                    style="max-height: 200px"
                    class="img-fluid rounded-circle mb-3"
                    src="img/testimonio2.png"
                    alt="..."
                  />
                  <h5>Alejandro V.</h5>
                  <p class="font-weight-light mb-0">
                    "Muchas gracias por ayudarme con mi página web, ha mejorado
                    bastante."
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                  <img
                    style="max-height: 200px"
                    class="img-fluid rounded-circle mb-3"
                    src="/img/testimonio3.png"
                    alt="..."
                  />
                  <h5>Teresa M.</h5>
                  <p class="font-weight-light mb-0">
                    "Es la mejor ayuda para el hogar que he encontrado, es muy
                    responsable."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
<!-- Footer-->
<footer class="footer bg-light pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
        <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <a href="/terminos">Términos y condiciones</a>
          </li>
          <li class="list-inline-item">⋅</li>
          <li class="list-inline-item">
            <a href="/privacidad">Política de privacidad</a>
          </li>
          <li class="list-inline-item">|</li>
          <li class="list-inline-item">
            <a
              href="https://www.facebook.com/profile.php?id=61561728719659"
              target="_blank"
              ><i
                class="fa-brands fa-facebook-f"
                style="font-size: xx-large"
              ></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.instagram.com/unayudaoficial" target="_blank"
              ><i class="fa-brands fa-instagram" style="font-size: xx-large"></i
            ></a>
          </li>
        </ul>
        <p class="text-muted small mb-4 mb-lg-0">
          &copy; unAyuda.com 2024. Todos los derechos reservados.
        </p>
      </div>
    </div>
  </div>
</footer>
