import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { ErrorComponent } from './pages/error/error.component';
import { CreaPublicacionComponent } from './pages/crea-publicacion/crea-publicacion.component';
import { EditarPublicacionComponent } from './pages/editar-publicacion/editar-publicacion.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrarComponent } from './pages/registrar/registrar.component';
import { OauthComponent } from './components/oauth/oauth.component';
import { AvisoComponent } from './pages/aviso/aviso.component';
import { TerminosComponent } from './pages/terminos/terminos.component';
import { PrivacidadComponent } from './pages/privacidad/privacidad.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'publicacion',
    component: CreaPublicacionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'publicacion-activa',
    component: EditarPublicacionComponent,
    canActivate: [AuthGuard],
  },
  { path: 'aviso/:firstParam', component: AvisoComponent },
  { path: 'login', component: LoginComponent },
  { path: 'terminos', component: TerminosComponent },
  { path: 'privacidad', component: PrivacidadComponent },
  { path: 'oauth', component: OauthComponent },
  { path: 'registrar', component: RegistrarComponent },
  { path: 'error', component: ErrorComponent },

  { path: '**', redirectTo: '', pathMatch: 'full' }, // Redirige cualquier ruta desconocida a home
];
