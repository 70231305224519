import { Component,NgModule,OnInit} from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule,MatHint} from '@angular/material/form-field';
import { SpinnerService } from '../../services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { FormsModule,FormBuilder, FormGroup, Validators,ReactiveFormsModule } from '@angular/forms';
import { CommonModule ,NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { AlertaComponent } from '../../modals/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { CiudadesService } from '../../services/ciudades.service';
@Component({
  selector: 'app-crea-publicacion',
  standalone: true,
  imports: [CommonModule,NgIf,
    HeaderComponent,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule
  ],
  templateUrl: './crea-publicacion.component.html',
  styleUrl: './crea-publicacion.component.css'
})
export class CreaPublicacionComponent implements OnInit{
  loading: boolean = false;
  publicacionForm: FormGroup;
  tipos : any[] | any = [];
  idTipo : any;
  ciudades : any;
  ciudad : any;
  fotoCargada : boolean = false;

  constructor(private http: HttpClient,private spinnerService: SpinnerService,
    private fb: FormBuilder,private router: Router, private globalService: GlobalService,
        public dialog: MatDialog,private ciudadesService: CiudadesService) {
      this.publicacionForm = this.fb.group({
        foto: [''],
        nombre: ['', [Validators.required, Validators.minLength(3)]],
        valor: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(10000), Validators.max(1000000)]],
        descripcion: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(1000)]],
        whatsapp: ['', [Validators.required, Validators.pattern('^\\+?\\d{10,15}$')]],
        facebook: [''],
        instagram: ['']
      });
      this.cargarPublicacionActual();
      this.cargarTiposPublicacion();
      this.idTipo = 1;
  }
  ngOnInit(): void {
    this.ciudadesService.getCiudades().subscribe(
      (response : any) => {
        this.ciudades = response.data;
        this.ciudad = response.data[0].id;
      },
      (error : any) => {
        console.error('Error al obtener ciudades', error);
      }
    );  
    this.setImagenDefault();
  }

  handleFileInput(event: Event): void {
     const input = event.target as HTMLInputElement;
     if (input.files) {
         this.fotoCargada = true;
         this.publicacionForm.patchValue({ foto: input.files.item(0) });
     }
   }
   setImagenDefault() {
    const imageUrl = 'img/no-image.jpg';
    this.http.get(imageUrl, { responseType: 'blob' }).subscribe(blob => {
      const file = new File([blob], 'default.jpg', { type: 'image/jpeg' });
      this.publicacionForm.patchValue({ foto: file });
    });
  }

  submit() {
    if (this.publicacionForm.valid) {
     this.loading = true;
     this.spinnerService.show();
     const formData: FormData = new FormData()
     formData.append('foto', this.publicacionForm.get('foto')?.value);
     formData.append('nombre', this.publicacionForm.get('nombre')?.value);
     formData.append('descripcion', this.publicacionForm.get('descripcion')?.value);
     formData.append('valor',this.publicacionForm.get('valor')?.value);
     formData.append('facebook', this.publicacionForm.get('facebook')?.value);
     formData.append('instagram', this.publicacionForm.get('instagram')?.value);
     formData.append('whatsapp', this.publicacionForm.get('whatsapp')?.value);
     formData.append('tipo', this.idTipo);
     formData.append('ciudad', this.ciudad);


     this.http.post<any>('/api/publicacion', formData).pipe(
       tap(
        (response) => {
          console.log('Respuesta:', response);
          this.loading = false;
          this.spinnerService.hide();
          const dialogRef = this.dialog.open(AlertaComponent, {
            data: "Publicación creada con éxito",
              height: '30%',
              width: '400px'
          });
          dialogRef.afterClosed().subscribe(result => {
            window.location.href = "/";
          });
        },
        (error) => {
          console.error('Error:', error);
          this.loading = false;
          this.spinnerService.hide();
          const dialogRef = this.dialog.open(AlertaComponent, {
            data: "Ocurrio un problema al crear publicación , "+((error.error.mensaje) ? error.error.mensaje : "Error desconocido"),
              height: '40%',
              width: '400px'
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      )
     ).subscribe();
    } else {
    console.log("Formulario no válido");
  }
  
  }



  cargarPublicacionActual(): void {
    this.loading = true;
    this.spinnerService.show();
    this.http.get("/api/publicacion/actual")
      .subscribe(response => {
        this.loading = false;
        this.spinnerService.hide();
        this.redirigirConObjeto(response);
      }, error => {
        console.error('Error al cargar publicaciones:', error);
        this.loading = false;
        this.spinnerService.hide();
      });
  }

  redirigirConObjeto(objeto: any) {
    this.globalService.setPublicacion(objeto.data);
    this.router.navigate(['/publicacion-activa']);
  }
  cargarTiposPublicacion(): void {
    if(this.globalService.getTiposPublicacion() == null || undefined){
    this.http.get('/api/publicacion/tipos').subscribe({
      next: (data) => { 
        this.globalService.setTiposPublicacion(data);
        this.tipos = this.globalService.getTiposPublicacion();
        this.loading = false;
        this.spinnerService.hide();
      },
      complete: () => { console.log("Completo");

       },
       error: (data) => {
        this.loading = false;
        this.spinnerService.hide();
        console.log("Error");
        console.log(data);
      }
    });
   }else{
    this.tipos = this.globalService.getTiposPublicacion();
   }
  }

  onTypeChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = Number(selectElement.value);
    this.idTipo = selectedId;
  }
  onCiudadChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = Number(selectElement.value);
    this.ciudad = selectedId;
  }
  

}