import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
@Component({
  selector: 'app-oauth',
  standalone: true,
  imports: [],
  templateUrl: './oauth.component.html',
  styleUrl: './oauth.component.css'
})
export class OauthComponent implements OnInit {

  constructor(private router: Router,private spinner: SpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    // Leer el fragmento de la URL
    const fragment = window.location.hash.substring(1);
    console.log('Fragment:', fragment);

    // Parsear el fragmento en parámetros clave-valor
    const params = this.parseFragment(fragment);
    console.log('Parsed Parameters:', params);

    // Ejemplo: Utilizar un parámetro específico
    if (params['access_token']) {
      console.log('Access Token:', params['access_token']);
      // Redirigir a una URL personalizada con el access_token
      this.redirectToCustomUrl(params['access_token']);
    }else{
      window.location.href = '/error';
    }
  }

  // Método para parsear el fragmento en un objeto de parámetros clave-valor
  private parseFragment(fragment: string): { [key: string]: string } {
    return fragment.split('&').reduce((result: { [key: string]: string }, item) => {
      const parts = item.split('=');
      result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      return result;
    }, {});
  }

  // Método para redirigir a una URL personalizada con el access_token
  private redirectToCustomUrl(accessToken: string): void {
    const customUrl = `/api/oauth/loginSuccess?access_token=${accessToken}`;
    window.location.href = customUrl;
  }
}