// geo-location.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface GeoLocationResponse {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  postal: string;
  timezone: string;
}

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  private apiUrl = 'https://ipinfo.io/json?token=bec354bf2d58f1';

  constructor(private http: HttpClient) {}

  getGeoLocation(): Observable<GeoLocationResponse> {
    return this.http.get<GeoLocationResponse>(this.apiUrl);
  }
}
