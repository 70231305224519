import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'; // Importa CommonModule
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; // Importa FormsModule
import { SpinnerService } from '../../services/spinner.service';
import { ActivatedRoute } from '@angular/router';

declare var bootstrap: any;
declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute
  ) {}

  email: string = '';
  contrasena: string = '';
  errorMessage: string = '';
  emailValido: boolean = true;
  passwordValida: boolean = true;
  facebookSdkLoaded: boolean = false; // Booleano para indicar si el SDK se ha cargado
  user: any = null;
  loading: boolean = false;

  iniciarSesion() {
    console.log('Iniciando sesion');
    const email = (document.getElementById('emailInput') as HTMLInputElement)
      .value;
    const contrasena = (
      document.getElementById('passwordInput') as HTMLInputElement
    ).value;

    if (!this.validarEmail(email)) {
      this.emailValido = false;
      return;
    } else {
      this.emailValido = true;
    }

    if (contrasena.length < 6) {
      this.passwordValida = false;
      return;
    } else {
      this.passwordValida = true;
    }

    const usuario = {
      email: email,
      contrasena: contrasena,
    };

    this.loading = true;
    this.spinnerService.show();

    this.http.post('/api/login', usuario).subscribe({
      next: (data) => {
        window.location.href = '/';
      },
      complete: () => {
        console.log('Completo');
      },
      error: (data) => {
        this.loading = false;
        this.spinnerService.hide();

        console.log('Error');
        console.log(data);
        this.errorMessage = data.error.mensaje;
        this.showErrorModal();
      },
    });
  }

  validarEmail(email: string): boolean {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }
  // Alterna entre DEV y PROD

  // Local
  //private redirectUrl: string = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.profile%20https%3A//www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=http%3A//localhost:4201/oauth&client_id=155254390648-hs5co78563gat6qd0tcjsben4qv63ks2.apps.googleusercontent.com';

  //Prod
  private redirectUrl: string =
    'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.profile%20https%3A//www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=https%3A//unayuda.com/oauth&client_id=178118169305-1278h5fa1nmopjps3stkfrnqc1fhbbvr.apps.googleusercontent.com';

  ngOnInit(): void {
    this.loading = true;
    this.spinnerService.show();
    this.detectProblem();
    this.loadFacebookSDK();
    this.loading = false;
    this.spinnerService.hide();
  }

  loginGoogle(): void {
    this.loading = true;
    this.spinnerService.show();

    window.location.href = this.redirectUrl;
  }

  loadFacebookSDK(): void {
    // Configuración de fbAsyncInit para inicializar el SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        //Alternar entre Dev y Prod
        // appId: '1466431497582508', //Local
        appId: '443160701893809', // Prod
        cookie: true,
        xfbml: true,
        version: 'v20.0', // Actualiza la versión del SDK aquí
      });

      window.FB.AppEvents.logPageView();
    };
    this.facebookSdkLoaded = true; // Actualiza el booleano cuando el SDK se carga
    //this.spinner.hide();
    // Cargar el SDK de Facebook
    ((d, s, id) => {
      let js: any;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      d.body.appendChild(js); // Agregar el script al final del body
    })(document, 'script', 'facebook-jssdk');
  }

  loginWithFacebook(): void {
    this.loading = true;
    this.spinnerService.show();

    // Esperar hasta que el SDK se haya cargado antes de intentar usarlo
    if (window.FB) {
      window.FB.login(
        (response: any) => {
          if (response.authResponse) {
            // Obtener la información del usuario después de la autenticación
            window.FB.api(
              '/me',
              { fields: 'name,email,picture' },
              (userInfo: any) => {
                this.user = userInfo;
                this.registerFacebook(this.user);
              }
            );
          } else {
            console.log('Login por facebook fallido');
            this.loading = false;
            this.spinnerService.hide();
          }
        },
        { scope: 'email' }
      );
    } else {
      console.error('Facebook SDK aun no termina de cargar.');
      this.loading = false;
      this.spinnerService.hide();
    }
  }

  registerFacebook(userInfo: any) {
    this.loading = true;
    this.spinnerService.show();
    this.http.post('/api/oauth/facebook', userInfo).subscribe({
      next: (data) => {
        window.location.href = '/';
      },
      complete: () => {
        console.log('Completo');
      },
      error: (data) => {
        this.loading = false;
        this.spinnerService.hide();

        this.errorMessage = data.error.mensaje;
        this.showErrorModal();
      },
    });
  }

  showErrorModal() {
    const modalElement = document.getElementById('errorModal2');
    const modal = new bootstrap.Modal(modalElement);
    modal.show();
  }
  detectProblem(): void {
    this.route.queryParamMap.subscribe((params) => {
      const firstQueryParam = params.get('error');
      if (firstQueryParam) {
        this.errorMessage = 'Debe iniciar sesión para ver esta página';
        const modalElement = document.getElementById('errorModal2');
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }
      console.log(firstQueryParam);
    });
  }
}
