import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Importa FormsModule
import { CommonModule } from '@angular/common'; // Importa CommonModule
import { HttpClient } from '@angular/common/http'; // Importa HttpClient
import { SpinnerService } from '../../services/spinner.service';

declare var bootstrap: any;

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, // Añade FormsModule a los imports
  ],
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.css'],
})
export class RegistrarComponent implements OnInit {
  errorMessage: string = '';
  nombre: string = '';
  correo: string = '';
  contrasena: string = '';
  repetirContrasena: string = '';
  aceptaTerminos: boolean = false;

  constructor(private http: HttpClient, private spinner: SpinnerService) {}
  get formularioValido(): boolean {
    return (
      this.nombreValido() &&
      this.correoValido() &&
      this.contrasenasValidas() &&
      this.aceptaTerminos
    );
  }

  nombreValido(): boolean {
    return this.nombre.length >= 2;
  }

  correoValido(): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(this.correo);
  }

  contrasenasValidas(): boolean {
    const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;
    return (
      this.contrasena === this.repetirContrasena &&
      passwordPattern.test(this.contrasena)
    );
  }

  get mensajeNombre(): string {
    if (this.nombre.length === 0) return '';
    return this.nombreValido()
      ? ''
      : 'El nombre debe tener al menos 2 caracteres.';
  }

  get mensajeCorreo(): string {
    if (this.correo.length === 0) return '';
    return this.correoValido() ? '' : 'Correo electrónico no válido.';
  }

  get mensajeContrasena(): string {
    if (this.contrasena.length === 0) return '';
    if (this.contrasena !== this.repetirContrasena)
      return 'Las contraseñas no coinciden.';
    return this.contrasenasValidas()
      ? ''
      : 'La contraseña debe tener al menos 6 caracteres alfanuméricos.';
  }

  get mensajeTerminos(): string {
    return this.aceptaTerminos
      ? ''
      : 'Debe aceptar los términos y condiciones.';
  }

  ngOnInit(): void {
    console.log('Init register');
  }

  onSubmit(): void {
    this.spinner.show();

    console.log('Enviando peticion para crear usuario ...');
    if (this.formularioValido) {
      const formData = {
        nombre: this.nombre,
        correo: this.correo,
        contrasena: this.contrasena,
      };
      this.http.post('/api/register', formData).subscribe({
        next: (data) => {
          console.log('Next');
          console.log(data);
          window.location.href = '/';
        },
        complete: () => {
          console.log('Completo');
        },
        error: (data) => {
          this.spinner.hide();
          console.log('Error');
          console.log(data);
          this.errorMessage = data.error.mensaje;
          this.showErrorModal();
        },
      });
    }
  }

  showErrorModal() {
    const modalElement = document.getElementById('errorModal');
    const modal = new bootstrap.Modal(modalElement);
    modal.show();
  }
}
