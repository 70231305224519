<app-header></app-header>
<div class="container mt-5">
   <div class="row justify-content-lg-start">
      <h2 class="text-center mb-4" style="font-size:xx-large;">Completa los servicios que ofrecerás!</h2>
      <p>¿Qué tipo de ayuda estás ofreciendo? Asegúrate que cumpla con los <a href="/terminos" target="_blank"> términos y condiciones</a></p>

      <form [formGroup]="publicacionForm" (ngSubmit)="submit()">
      <div class="px-4">
         <h3 for="foto" class="form-label">Selecciona tu mejor foto:</h3>
         <input style="max-width: 450px;"  class="form-control" type="file" id="foto"  (change)="handleFileInput($event)">
         <div *ngIf="publicacionForm.get('foto')?.hasError('required')">
            <small class="text-danger">* La foto es obligatoria, peso max. 3mb.</small>
         </div>
      
<div class="row mt-2 mb-1">
   <div class="col-md-4">
    <h2 for="typeSelect" class="form-label mt-3">Que tipo de ayuda ofrecerás ? :</h2>
    <select  style="max-width: 350px;" id="typeSelect" class="form-select" (change)="onTypeChange($event)">
      <option *ngFor="let tipo of tipos" [value]="tipo.id">{{ tipo.nombre }}</option>
    </select>
    <h2 for="citySelect" class="form-label mt-3">En que cuidad puedes ayudar ? :</h2>
    <select style="max-width: 350px;"  id="citySelect" class="form-select" (change)="onCiudadChange($event)">
      <option *ngFor="let ciudad of ciudades" [value]="ciudad.id">{{ ciudad.nombre }}</option>
    </select>
</div>
   <div class="col-md-1 mt-3" style="min-width: 350px;" >
      <h2>Tu aviso : </h2>
      <mat-form-field appearance="outline" [style.width.px]=350>
         <mat-label>Título de aviso</mat-label>
         <div class="mx-3 mt-3 px-3 md">
         <input matInput placeholder="Título de aviso" style="font-size: large;" formControlName="nombre">
         </div>
         <mat-icon matSuffix>face</mat-icon>
         <div *ngIf="publicacionForm.get('nombre')?.invalid && (publicacionForm.get('nombre')?.dirty || publicacionForm.get('nombre')?.touched)">
            <small class="text-danger" *ngIf="publicacionForm.get('nombre')?.hasError('required')">* El título es obligatorio.</small>
            <small class="text-danger" *ngIf="publicacionForm.get('nombre')?.hasError('minlength')">* El título debe tener al menos 3 letras.</small>
         </div>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]=350>
         <mat-label>Valor de tu servicio</mat-label>
         <input matInput type="number" inputmode="numeric" placeholder="ej: 45.000" style="font-size: x-large;" formControlName="valor">
         <mat-icon matSuffix>attach_money</mat-icon>
         <div *ngIf="publicacionForm.get('valor')?.invalid && (publicacionForm.get('valor')?.dirty || publicacionForm.get('valor')?.touched)">
            <small class="text-danger" *ngIf="publicacionForm.get('valor')?.hasError('required')">* El valor es obligatorio.</small>
            <small class="text-danger" *ngIf="publicacionForm.get('valor')?.hasError('pattern')">* El valor debe ser un número.</small>
            <small class="text-danger" *ngIf="publicacionForm.get('valor')?.hasError('min')">* El valor mínimo es de 1.000.</small>
            <small class="text-danger" *ngIf="publicacionForm.get('valor')?.hasError('max')">* El valor máximo es 1.000.000.</small>
         </div>
      </mat-form-field>
     
  

</div>
</div>
      </div>
      <div class="container">
         <div class="row mt-1 mb-3">
            <div class="col">
               <h2>Datos de contacto</h2>
               <mat-form-field appearance="outline" style="margin-right: 10px;" [style.width.px]=300>
                  <mat-label>Whatsapp</mat-label>
                  <input type="number" inputmode="numeric" matInput placeholder="ej: 569 1231234" style="font-size: larger;" formControlName="whatsapp">
                  <mat-icon matSuffix>phone</mat-icon>
                  <div *ngIf="publicacionForm.get('whatsapp')?.invalid && (publicacionForm.get('whatsapp')?.dirty || publicacionForm.get('whatsapp')?.touched)">
                     <small class="text-danger" *ngIf="publicacionForm.get('whatsapp')?.hasError('required')">* El número de Whatsapp es obligatorio.</small>
                     <small class="text-danger" *ngIf="publicacionForm.get('whatsapp')?.hasError('pattern')">* El formato del número de Whatsapp es incorrecto.</small>
                  </div>
               </mat-form-field>
               <mat-form-field appearance="outline" style="margin-right: 10px;" [style.width.px]=300>
                  <mat-label>Facebook</mat-label>
                  <input matInput placeholder="ej: minombre" style="font-size: larger;" formControlName="facebook">
                  <mat-icon matSuffix>facebook</mat-icon>
                  <span matTextPrefix>facebook.com/</span>
                  <div *ngIf="publicacionForm.get('facebook')?.invalid && (publicacionForm.get('facebook')?.dirty || publicacionForm.get('facebook')?.touched)">
                     <small class="text-danger">* El nombre de usuario de Facebook es obligatorio.</small>
                  </div>
               </mat-form-field>
               <mat-form-field appearance="outline"  [style.width.px]=300>
                  <mat-label>Instagram</mat-label>
                  <input matInput placeholder="ej: mi.perfil_" style="font-size: larger;" formControlName="instagram">
                  <mat-icon matSuffix>person_pin</mat-icon>
                  <span matTextPrefix>instagram.com/</span>

                  <div *ngIf="publicacionForm.get('instagram')?.invalid && (publicacionForm.get('instagram')?.dirty || publicacionForm.get('instagram')?.touched)">
                     <small class="text-danger">* El nombre de usuario de Instagram es obligatorio.</small>
                  </div>
               </mat-form-field>
            </div>
            <mat-form-field appearance="outline" >
               <mat-label>La descripción de tu servicio</mat-label>
               <textarea matInput  rows="5" placeholder="descripción de tus servicios" style="font-size: large;" formControlName="descripcion"></textarea>
               <div *ngIf="publicacionForm.get('descripcion')?.invalid && (publicacionForm.get('descripcion')?.dirty || publicacionForm.get('descripcion')?.touched)">
                  <small class="text-danger" *ngIf="publicacionForm.get('descripcion')?.hasError('required')">* La descripción es obligatoria.</small>
                  <small class="text-danger" *ngIf="publicacionForm.get('descripcion')?.hasError('minlength')">* La descripción debe tener al menos 10 caracteres.</small>
                  <small class="text-danger" *ngIf="publicacionForm.get('descripcion')?.hasError('maxlength')">* La descripción es demasiado larga, max. 1.000 caracteres.</small>

               </div>
               </mat-form-field>
         </div>
      </div>
      <div class="text-center mt-1">
         <button type="submit" class="btn btn-warning btn-block btn-lg">Publicar aviso!</button>
      </div>
      <p></p>
      </form>
    
   </div>
</div>