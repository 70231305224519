<header>
  <div class="bg-light text-dark border-bottom">
    <div
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start"
    >
      <a
        href="/"
        class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-dark text-decoration-none"
      >
        <img
          src="/img/unayuda_plain.png"
          alt="logo de portal"
          style="max-height: 80px; max-width: 120px"
        />
      </a>
      <div>
        <a
          *ngIf="usuario !== undefined && usuario.nombre !== undefined"
          class="mt-5 mx-3 mb-3"
          href="/api/logout"
          style="color: darkgray"
          >Cerrar sesión</a
        >
        <ul
          class="nav col-8 col-lg-auto mx-5 justify-content-center my-md-0 text-small"
        >
          <li>
            <a
              *ngIf="usuario !== undefined && usuario.nombre !== undefined"
              class="nav-link text-dark"
            >
              <p *ngIf="usuario !== undefined && usuario.nombre !== undefined">
                Bienvenido,
                <label style="font-weight: bold"> {{ usuario.nombre }}</label>
              </p>
            </a>
          </li>
          <li>
            <button
              *ngIf="usuario !== undefined && usuario.nombre !== undefined"
              type="button"
              onclick="location.href='/publicacion'"
              class="btn btn-warning btn-block mb-1 mt-1"
            >
              Publicar mi Aviso
            </button>
            <button
              *ngIf="usuario == undefined || usuario.nombre == undefined"
              type="button"
              onclick="location.href='/login'"
              class="btn btn-warning btn-block mb-1 mt-1"
            >
              Publicar mi Aviso
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
