import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, NgFor,NgIf,NgClass } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of,tap,map,catchError,shareReplay } from 'rxjs';
import { SpinnerService } from '../../services/spinner.service';
import { GlobalService } from '../../services/global.service';
import { AlertaComponent } from '../../modals/alerta/alerta.component';
import {
  MatDialog,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { HeaderComponent } from '../../components/header/header.component';
@Component({
  selector: 'app-aviso',
  standalone: true,
  imports: [HeaderComponent, MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    NgIf],
  templateUrl: './aviso.component.html',
  styleUrl: './aviso.component.css'
})
export class AvisoComponent implements OnInit {

  idBase64 : any ;
  aviso : any;

  constructor(private route: ActivatedRoute,private http: HttpClient,
    private globalService: GlobalService,public dialog: MatDialog,
    private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.getFirstPathVariable();
    this.cargarPublicacionDetalle(this.idBase64);
  }

  getFirstPathVariable() {
    const firstPathVariable = this.route.snapshot.paramMap.keys[0];
    const value = this.route.snapshot.paramMap.get(firstPathVariable);
    console.log(`VARIABLE: ${firstPathVariable} = ${value}`);
    this.idBase64 = value;
  }

  cargarPublicacionDetalle(id: string): any {
    const url = `api/publicacion/${id}`;
      this.http.get(url).pipe(
        tap((response: any) => {
        }),
        map((response: any) => {
          if(response.mensaje.indexOf('Limite')>=0){
            return "Limite"
          }
          return response.data;
        }),
        catchError((error) => {
          return of(false);
        }),
        shareReplay(1) // Compartir el resultado de la primera petición entre suscripciones
      ).subscribe(data => {
        this.spinnerService.hide();

        if (data != null && data != undefined && data !="Limite" && data) {
         this.aviso = data;
        } else {
          console.log("Ocurrio un error al consultar detalle");       
          console.log(data);
          if(data =="Limite"){
            const dialogRef = this.dialog.open(AlertaComponent, {
            data: "Ud alcanzó el limite de visualizaciones gratuitas por el día de hoy.",
              height: '30%',
              width: '400px'
           });
          dialogRef.afterClosed().subscribe(result => {
            window.location.href = "/";
          });
         }else{
          const dialogRef = this.dialog.open(AlertaComponent, {
            data: "El aviso consultado ya no existe.",
              height: '30%',
              width: '400px'
           });
          dialogRef.afterClosed().subscribe(result => {
            window.location.href = "/";
          });
         }
        }
      });
  }

  formatNumberWithCommas(num: number): string {
    // Convertir el número a string
    let numStr = num.toString();

    // Separar la parte entera de la parte decimal
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Agregar puntos cada tres dígitos en la parte entera
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Retornar el número formateado
    return formattedIntegerPart + decimalPart;
}
}
