import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerDialogRef: MatDialogRef<SpinnerComponent> | undefined;

  constructor(private dialog: MatDialog) {}

  show(): void {
    if (!this.spinnerDialogRef) {
      this.spinnerDialogRef = this.dialog.open(SpinnerComponent, {
        disableClose: true,
        panelClass: 'transparent-dialog',
      });
    }
  }

  hide(): void {
    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close();
      this.spinnerDialogRef = undefined;
    }
  }
}
