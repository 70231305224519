<app-header></app-header>
<div class="container mt-2 text-center justify-content-center">
  <h1 class="mb-1 mt-1" style="font-size: 2.5rem">
    ¿ Que servicio necesitas ?
  </h1>
  <!-- Título agregado -->
  <div class="selectores justify-content-center">
    <ng-select
      class="custom p-3"
      [items]="tipos"
      bindLabel="nombre"
      bindValue="id"
      placeholder="Seleccione un tipo de servicio"
      [searchable]="true"
      (change)="onTypeChange($event)"
    >
    </ng-select>

    <ng-select
      class="custom p-3"
      [items]="ciudades"
      bindLabel="nombre"
      bindValue="nombre"
      placeholder="Seleccione ciudad"
      [searchable]="true"
      (change)="onCityChange($event)"
    >
    </ng-select>
  </div>
</div>

<!-- Carga publicaciones  -->
<div class="container">
  No esperes más y encuentra la ayuda que necesitas, explora nuestros socios:
  <div class="row justify-content-lg-start">
    <div *ngFor="let publicacion of this.publicaciones" class="col-md-3">
      <div
        [ngClass]="{
          'card-premium bg-light': publicacion.destacada == true,
          'custom-card bg-light': !publicacion.destacada
        }"
      >
        <img class="card-img-top" alt="..." src="{{ publicacion.foto }}" />
        <div *ngIf="publicacion.destacada">
          <i class="mb-2 fa fa-diamond" style="color: #e6b400"></i
          ><em><small class="text-muted"> Destacado</small></em>
        </div>
        <p>
          <i class="fa-solid fa-location-dot"></i>
          {{ publicacion.ciudadNombre }} | {{ publicacion.fechaCreacion }}
        </p>
        <div class="text-center">
          <h2 class="mb-2" style="font-size: 30px">{{ publicacion.nombre }}</h2>
          <div class="justify-content-lg-start">
            <h4 class="mb-2">
              ${{ formatNumberWithCommas(publicacion.valor) }} /
              {{ publicacion.tipoNombre }}
            </h4>
          </div>
          <p>{{ publicacion.descripcion }}</p>

          <button
            type="button"
            class="btn btn-warning btn-block"
            (click)="cargarPublicacionDetalle(publicacion.id)"
          >
            Datos de contacto
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<nav aria-label="Paginacion" class="mt-3 py-2">
  <ul class="pagination justify-content-center">
    <li class="page-item px-2">
      <a
        *ngIf="this.page > 0"
        type="button"
        class="btn btn-outline-secondary"
        (click)="anterior()"
        >Anterior</a
      >
    </li>
    <li class="page-item">
      <a class="page-link"> {{ this.page + 1 }}</a>
    </li>
    <li class="page-item px-2">
      <a
        *ngIf="this.publicaciones.length == this.size"
        type="button"
        class="btn btn-outline-secondary"
        (click)="siguiente()"
        >Siguiente</a
      >
    </li>
  </ul>
</nav>

<!-- Footer-->
<footer class="footer bg-light pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
        <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <a href="/terminos">Términos y condiciones</a>
          </li>
          <li class="list-inline-item">⋅</li>
          <li class="list-inline-item">
            <a href="/privacidad">Política de privacidad</a>
          </li>
          <li class="list-inline-item">|</li>
          <li class="list-inline-item">
            <a
              href="https://www.facebook.com/profile.php?id=61561728719659"
              target="_blank"
              ><i
                class="fa-brands fa-facebook-f"
                style="font-size: xx-large"
              ></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.instagram.com/unayudaoficial" target="_blank"
              ><i class="fa-brands fa-instagram" style="font-size: xx-large"></i
            ></a>
          </li>
        </ul>
        <p class="text-muted small mb-4 mb-lg-0">
          &copy; unAyuda.com 2024. Todos los derechos reservados.
        </p>
      </div>
    </div>
  </div>
</footer>
