import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { CommonModule } from '@angular/common'; // Importa CommonModule
import { SessionService } from '../../services/session.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  usuario: any;

  constructor(
    private globalService: GlobalService,
    private session: SessionService
  ) {
    this.usuario = globalService.getUsuario();
    this.reloadUser();
  }
  ngOnInit(): void {
    this.reloadUser();
  }

  reloadUser(): void {
    if (this.usuario == undefined || this.usuario == null) {
      this.usuario = this.session.getUser().subscribe(
        (response: any) => {
          this.usuario = response.data.principal;
        },
        (error: any) => {
          // No hay usuario logeado
        }
      );
    }
  }
}
