import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeoLocationService } from './geo-location.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CiudadesService {
  pais: any;

  constructor(
    private http: HttpClient,
    private geoLocationService: GeoLocationService
  ) {}

  getCiudades(): Observable<any> {
    return this.geoLocationService.getGeoLocation().pipe(
      switchMap((data) => {
        const pais = data.country;
        return this.http.get<any>(`/api/geo/ciudades/${pais}`);
      })
    );
  }

  getCiudadesCL(): Observable<any> {
    return this.http.get<any>(`/api/geo/ciudades/CL`);
  }
}
