import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GlobalService } from '../../services/global.service';
import { HeaderComponent } from '../../components/header/header.component';
import { SpinnerService } from '../../services/spinner.service';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { NgSelectModule } from '@ng-select/ng-select';
import { CiudadesService } from '../../services/ciudades.service';
import { Base64 } from 'js-base64';
import { SessionService } from '../../services/session.service';

export interface ResponseDTO {
  mensaje: string;
  data: any;
  // Añade más campos según sea necesario
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    NgSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    NgFor,
    NgIf,
    NgClass,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  loading: boolean = false;
  usuario: any;
  name: any;
  publicaciones: any[] | any = [];
  page: number = 0;
  size: number = 12;
  tipos: any[] | any = [];
  ciudades: any[] = [];
  tipo: any = null;
  ciudad: any = null;

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private ciudadesService: CiudadesService,
    private sessionService: SessionService
  ) {
    this.usuario = globalService.getUsuario();
  }

  ngOnInit() {
    this.loading = true;
    this.spinnerService.show();
    this.cargarTiposPublicacion();
    this.cargarCiudades();
    this.cargarPublicaciones();
  }

  cargarPublicacionDetalle(id: number): any {
    const numStr = id.toString();
    const base64Encoded = Base64.encode(numStr);
    let modifiedString: string = base64Encoded.replace(/=/g, '');
    window.location.href = '/aviso/' + modifiedString;
  }
  cargarCiudades(): void {
    this.ciudadesService.getCiudades().subscribe(
      (response: any) => {
        this.ciudades = response.data;
      },
      (error: any) => {
        console.error('Error al obtener ciudades', error);
      }
    );
  }
  cargarTiposPublicacion(): void {
    if (this.globalService.getTiposPublicacion() == null || undefined) {
      this.http.get('/api/publicacion/tipos').subscribe({
        next: (data) => {
          this.globalService.setTiposPublicacion(data);
          this.tipos = this.globalService.getTiposPublicacion();
        },
        complete: () => {
          // Completo
        },
        error: (data) => {
          console.log('Error');
          console.log(data);
        },
      });
    } else {
      this.tipos = this.globalService.getTiposPublicacion();
    }
  }

  cargarPublicaciones(): void {
    this.loading = true;
    this.spinnerService.show();
    const url = `/api/publicacion/filter?page=${this.page}&ciudad=${this.ciudad}&tipo=${this.tipo}`;

    const startTime = Date.now();

    this.http
      .get<{ data: ResponseDTO }>(url)
      .pipe(
        tap((response) => {
          this.publicaciones = response.data;
        }),
        finalize(() => {
          const elapsed = Date.now() - startTime;
          const remainingTime = Math.max(500 - elapsed, 0);
          timer(remainingTime).subscribe(() => {
            this.loading = false;
            this.spinnerService.hide();
            this.scrollToTop();
          });
        })
      )
      .subscribe({
        error: (error) => {
          console.error('Error al cargar publicaciones:', error);
        },
      });
  }

  onTypeChange(event: any): void {
    if (event == null) this.tipo = null;
    else this.tipo = event.id;
    this.cargarPublicaciones();
  }

  onCityChange(event: any): void {
    if (event == null) this.ciudad = null;
    else this.ciudad = event.id;
    this.cargarPublicaciones();
  }

  siguiente() {
    this.page++;
    this.cargarPublicaciones();
  }
  anterior() {
    this.page--;
    this.cargarPublicaciones();
  }

  private scrollToTop(): void {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  }

  formatNumberWithCommas(num: number): string {
    // Convertir el número a string
    let numStr = num.toString();

    // Separar la parte entera de la parte decimal
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Agregar puntos cada tres dígitos en la parte entera
    let formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    );

    // Retornar el número formateado
    return formattedIntegerPart + decimalPart;
  }
}
