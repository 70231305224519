import { Component,OnInit } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [HeaderComponent],
  templateUrl: './error.component.html',
  styleUrl: './error.component.css'
})
export class ErrorComponent implements OnInit {
  usuario : any;
  mensaje : string = "Cargando ...";
  sesionTerminada : boolean = false;

  constructor(private globalService: GlobalService) {
  }
  ngOnInit(): void {
    this.usuario = this.globalService.getUsuario();
    if(this.usuario == undefined){
      this.mensaje = "Sesión caducada, por favor vuelva a iniciar sesión."
      this.sesionTerminada = true;
    }
  }
  
}