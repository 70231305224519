<app-header></app-header>
<div class="contenido">
  <p class="c4 c6 title" id="h.755f55vph91g">
    <span class="c5">Pol&iacute;ticas de Privacidad de unAyuda.com</span>
  </p>
  <p class="c4">
    <span class="c0"
      >En unAyuda.com nos comprometemos a proteger la privacidad de nuestros
      usuarios y el tratamiento de sus datos personales de conformidad con la
      Ley 19.628 sobre Protecci&oacute;n de la Vida Privada de Chile y
      dem&aacute;s normativa aplicable. Esta Pol&iacute;tica de Privacidad
      describe c&oacute;mo recopilamos, utilizamos, almacenamos y protegemos la
      informaci&oacute;n personal que nos proporcionan.</span
    >
  </p>
  <ol class="c2 lst-kix_a8p35oqt5l6q-0 start" start="1">
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Informaci&oacute;n recopilada: unAyuda.com recopila informaci&oacute;n
        personal proporcionada voluntariamente por los usuarios al registrarse y
        utilizar nuestros servicios, como nombre, correo electr&oacute;nico,
        n&uacute;mero de tel&eacute;fono, direcci&oacute;n y datos de pago.
        Tambi&eacute;n recopilamos informaci&oacute;n sobre el uso de nuestro
        sitio web y aplicaciones.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Uso de la informaci&oacute;n: Utilizamos la informaci&oacute;n
        recopilada para:</span
      >
    </li>
  </ol>
  <ul class="c2 lst-kix_o5bijchrfn8u-0 start">
    <li class="c1 li-bullet-0">
      <span class="c0">Gestionar y mejorar nuestros servicios</span>
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0">Procesar pagos y transacciones</span>
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Comunicarnos con los usuarios sobre actualizaciones y ofertas</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0">Prevenir actividades fraudulentas</span>
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Generar an&aacute;lisis estad&iacute;sticos y de mercado</span
      >
    </li>
  </ul>
  <ol class="c2 lst-kix_10keqf9okric-0 start" start="3">
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Compartir informaci&oacute;n: No vendemos ni alquilamos
        informaci&oacute;n personal a terceros. S&oacute;lo compartimos
        informaci&oacute;n con proveedores de servicios que nos ayudan a operar
        y con autoridades competentes cuando as&iacute; lo exija la ley.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Seguridad de los datos: Aplicamos medidas t&eacute;cnicas y
        organizativas para proteger la informaci&oacute;n personal contra acceso
        no autorizado, p&eacute;rdida, uso indebido o divulgaci&oacute;n.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Cookies y tecnolog&iacute;as similares: Utilizamos cookies y otras
        tecnolog&iacute;as similares para mejorar la experiencia de usuario y
        realizar an&aacute;lisis de tr&aacute;fico web. Los usuarios pueden
        configurar sus navegadores para rechazar o eliminar cookies.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Derechos de los titulares: Los usuarios tienen derecho a acceder,
        rectificar, cancelar y oponerse al tratamiento de sus datos personales
        de acuerdo con la Ley 19.628.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Menores de edad: Nuestros servicios no est&aacute;n dirigidos a menores
        de 18 a&ntilde;os. No recopilamos intencionalmente informaci&oacute;n
        personal de menores sin consentimiento de los padres o tutores.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Enlaces a terceros: Nuestro sitio web puede contener enlaces a sitios
        de terceros. Esta Pol&iacute;tica de Privacidad no se aplica a dichos
        sitios externos.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Cambios en la Pol&iacute;tica de Privacidad: Nos reservamos el derecho
        de modificar esta Pol&iacute;tica de Privacidad en cualquier momento.
        Cualquier cambio ser&aacute; publicado en nuestro sitio web.</span
      >
    </li>
    <li class="c3 li-bullet-0">
      <span class="c0"
        >Contacto: Si tiene preguntas o inquietudes sobre esta Pol&iacute;tica
        de Privacidad, puede contactarnos a trav&eacute;s de
        soporte&#64;unAyuda.com.</span
      >
    </li>
  </ol>
  <p class="c4">
    <span class="c0"
      >Al utilizar unAyuda.com, usted acepta las pr&aacute;cticas descritas en
      esta Pol&iacute;tica de Privacidad. Si no est&aacute; de acuerdo, le
      recomendamos no utilizar nuestros servicios.</span
    >
  </p>
  <p class="c4">
    <span class="c0"
      >Esta Pol&iacute;tica de Privacidad fue actualizada por &uacute;ltima vez
      el 3 de junio del 2024.</span
    >
  </p>
</div>
