import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private http: HttpClient, private globalService: GlobalService) {}
  getUser(): any {
    return this.http.get('/api/login/check-session', { withCredentials: true });
  }
}
