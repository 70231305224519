<app-header></app-header>
<div class="contenido">
  <p class="c1 c4 title" id="h.rpjckewh5tzu">
    <span class="c9"
      >Documento de Aceptaci&oacute;n de T&eacute;rminos y Condiciones</span
    >
  </p>
  <h1 class="c1 c4" id="h.78eywrxqpvme">
    <span class="c10">Introducci&oacute;n</span>
  </h1>
  <p class="c1">
    <span class="c0"
      >Bienvenido a unAyuda.com. Este documento establece los t&eacute;rminos y
      condiciones aplicables para el uso correcto de nuestro sistema web. Al
      utilizar unAyuda.com, usted acepta cumplir con los t&eacute;rminos y
      condiciones descritos a continuaci&oacute;n.</span
    >
  </p>
  <h3 class="c1 c4" id="h.kno3uo13yem8">
    <span class="c3">Definiciones</span>
  </h3>
  <ul class="c6 lst-kix_f5knebv33my6-0 start">
    <li class="c1 c2 li-bullet-0">
      <span class="c0"
        >Usuario: Persona que utiliza unAyuda.com, ya sea como proveedor o
        requirente de servicios.
      </span>
    </li>
    <li class="c1 c2 li-bullet-0">
      <span class="c0"
        >Proveedor de Servicios: Persona o entidad que ofrece servicios a
        trav&eacute;s de unAyuda.com.
      </span>
    </li>
    <li class="c1 c2 li-bullet-0">
      <span class="c0"
        >Requirente de Servicios: Persona o entidad que busca y contrata
        servicios a trav&eacute;s de unAyuda.com.
      </span>
    </li>
    <li class="c1 c2 li-bullet-0">
      <span class="c0"
        >Plataforma: El sitio web y las aplicaciones m&oacute;viles de
        unAyuda.com.
      </span>
    </li>
    <li class="c1 c2 li-bullet-0">
      <span class="c0"
        >T&eacute;rminos y Condiciones: El presente documento que establece las
        reglas y condiciones para el uso de unAyuda.com.</span
      >
    </li>
  </ul>
  <h3 class="c1 c4" id="h.oxd8dw3p9tev">
    <span class="c3">Misi&oacute;n de unAyuda.com </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >unAyuda.com tiene como misi&oacute;n principal conectar a personas que
      requieran un servicio con aquellas que puedan ofrecerlo. No nos hacemos
      responsables de que este servicio se lleve a cabo ni ofrecemos
      garant&iacute;as de ello. Nuestro foco es ser una plataforma que facilita
      la conexi&oacute;n entre personas.</span
    >
  </p>
  <h3 class="c1 c4" id="h.mf0fw8p5ssm3">
    <span class="c3">Legalidad de los Servicios</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >No se permite ofrecer ni solicitar servicios que est&eacute;n fuera del
      marco legal del pa&iacute;s en el que se ofrezcan o reciban dichos
      servicios, o que infrinjan cualquier ley o regulaci&oacute;n aplicable. Es
      responsabilidad de cada usuario asegurarse de que las transacciones y
      servicios ofrecidos o solicitados cumplan con todas las leyes y
      regulaciones aplicables.</span
    >
  </p>
  <h3 class="c1 c4" id="h.jib873nconol">
    <span class="c3"
      >Prohibici&oacute;n de Robo de Identidad y Adulteraci&oacute;n de Datos
    </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Queda estrictamente prohibido el robo de identidad y la
      adulteraci&oacute;n de datos para obtener beneficios en el sitio. Los
      usuarios deben proporcionar informaci&oacute;n veraz y precisa. Cualquier
      intento de manipulaci&oacute;n de los datos ser&aacute; sancionado
      conforme a nuestras pol&iacute;ticas, la Ley 19.628 sobre
      Protecci&oacute;n de la Vida Privada, la Ley 19.223 sobre Delitos
      Inform&aacute;ticos de Chile y la legislaci&oacute;n aplicable.</span
    >
  </p>
  <p class="c1 c5"><span class="c0"></span></p>
  <h3 class="c1 c4" id="h.9d3mkfz6fn1o">
    <span class="c3">Autorizaci&oacute;n para el Uso de Datos</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Al aceptar estos t&eacute;rminos, usted autoriza a unAyuda.com a guardar,
      analizar, reproducir y disponibilizar, para los fines que estime
      convenientes, los datos recopilados mediante su interacci&oacute;n con
      nuestros sistemas. Estos datos podr&aacute;n ser utilizados para mejorar
      nuestros servicios, realizar an&aacute;lisis estad&iacute;sticos,
      personalizar la experiencia del usuario, entre otros usos que consideremos
      apropiados.</span
    >
  </p>
  <h3 class="c1 c4" id="h.ce2us4twiy8k">
    <span class="c3">Protecci&oacute;n de Datos Personales </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >En cumplimiento con la Ley 19.628 sobre Protecci&oacute;n de la Vida
      Privada de Chile, el Reglamento General de Protecci&oacute;n de Datos
      (GDPR) de la Uni&oacute;n Europea y la Ley 19.496 sobre Protecci&oacute;n
      de los Derechos de los Consumidores de Chile, nos comprometemos a proteger
      la privacidad de nuestros usuarios. Los datos personales ser&aacute;n
      tratados conforme a nuestra Pol&iacute;tica de Privacidad, disponible en
      nuestro sitio web.</span
    >
  </p>
  <h3 class="c1 c4" id="h.854fvlbb4glv">
    <span class="c3">Pol&iacute;tica de Cookies </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >unAyuda.com utiliza cookies para mejorar la experiencia del usuario en
      nuestro sitio web. Al utilizar nuestro sitio, usted acepta el uso de
      cookies conforme a nuestra Pol&iacute;tica de Cookies, disponible en
      nuestro sitio web.</span
    >
  </p>
  <h3 class="c1 c4" id="h.se7pxcxj7b1g">
    <span class="c3">Responsabilidad del Usuario </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >El usuario se compromete a utilizar unAyuda.com de manera responsable y
      &eacute;tica, cumpliendo con todas las obligaciones legales y tributarias
      relacionadas con los servicios ofrecidos o solicitados. Cualquier
      incumplimiento de estos t&eacute;rminos y condiciones puede resultar en la
      suspensi&oacute;n o cancelaci&oacute;n de su cuenta, as&iacute; como en
      acciones legales si es necesario.</span
    >
  </p>
  <h3 class="c1 c4" id="h.3z4oq75xl2fv">
    <span class="c3">Uso Aceptable del Sitio</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Los usuarios deben abstenerse de publicar contenido ofensivo, realizar
      actividades fraudulentas, enviar spam, y otras conductas inapropiadas. El
      incumplimiento de esta pol&iacute;tica puede resultar en la
      suspensi&oacute;n o cancelaci&oacute;n de la cuenta del usuario.</span
    >
  </p>
  <h3 class="c1 c4" id="h.yyozlrdjy7vf">
    <span class="c3">Responsabilidad de unAyuda.com </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >unAyuda.com act&uacute;a &uacute;nicamente como un intermediario entre
      los usuarios y no se hace responsable de la calidad, seguridad, legalidad
      o cualquier otro aspecto de los servicios ofrecidos o solicitados a
      trav&eacute;s de la plataforma. Cualquier disputa entre los usuarios
      deber&aacute; ser resuelta entre ellos sin involucrar a unAyuda.com.</span
    >
  </p>
  <p class="c1 c5"><span class="c0"></span></p>
  <h3 class="c1 c4" id="h.e8oknisiax36">
    <span class="c3"
      >Relaci&oacute;n entre Proveedor y Requirente del Servicio</span
    >
  </h3>
  <p class="c1">
    <span class="c0"
      >La relaci&oacute;n entre el proveedor del servicio y el requirente es
      responsabilidad exclusiva de ellos mismos. unAyuda.com espera que ambas
      partes cumplan con la emisi&oacute;n de la boleta correspondiente cuando
      se realice una transacci&oacute;n. Adem&aacute;s, es responsabilidad de
      las partes determinar si el servicio es de car&aacute;cter temporal o
      permanente y asegurarse de que se ajusten al C&oacute;digo del Trabajo,
      las leyes de seguridad social y todas las dem&aacute;s leyes y
      regulaciones laborales aplicables.</span
    >
  </p>
  <h3 class="c1 c4" id="h.c9rc97tnx1yv">
    <span class="c3">Mejora de Posicionamiento del Servicio </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Ser&aacute; posible pagar para mejorar el posicionamiento del servicio
      solicitado u ofrecido en unAyuda.com. Sin embargo, no aseguramos que
      exista demanda suficiente, por lo tanto, no garantizamos que el pago por
      mejoras dentro del sitio garantice la concretizaci&oacute;n o venta del
      servicio.</span
    >
  </p>
  <h3 class="c1 c4" id="h.bnhqj6idck45">
    <span class="c3">Precios de los Servicios </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Los precios publicados en unAyuda.com son manejados por los ofertantes de
      los servicios. Nosotros solo podemos sugerir rangos dentro del mercado,
      pero no garantizamos que dichos precios sean los finales que realmente
      cobre el servicio. Los usuarios deben negociar y acordar los precios
      finales directamente entre ellos.</span
    >
  </p>
  <h3 class="c1 c4" id="h.220a9kik5j7c">
    <span class="c3">Propiedad Intelectual </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Todos los derechos de propiedad intelectual relacionados con el
      contenido, dise&ntilde;o y software de unAyuda.com son propiedad de
      unAyuda.com o sus licenciantes, y est&aacute;n protegidos por la Ley
      17.336 sobre Propiedad Intelectual de Chile. Queda prohibida la
      reproducci&oacute;n, distribuci&oacute;n, modificaci&oacute;n o cualquier
      uso no autorizado de dichos derechos.</span
    >
  </p>
  <h3 class="c1 c4" id="h.d3bvhnpwqe28">
    <span class="c3">Edad M&iacute;nima de Usuario</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Solo se permitir&aacute; el uso de unAyuda.com a personas mayores de 18
      a&ntilde;os. Al registrarse y utilizar nuestra plataforma, el usuario
      declara y garantiza tener al menos 18 a&ntilde;os de edad. unAyuda.com se
      reserva el derecho de solicitar la verificaci&oacute;n de la edad y de
      suspender o cancelar cuentas que no cumplan con este requisito.</span
    >
  </p>
  <p class="c1 c5"><span class="c0"></span></p>
  <p class="c1 c5"><span class="c0"></span></p>
  <p class="c1 c5"><span class="c0"></span></p>
  <p class="c1 c5"><span class="c0"></span></p>
  <h3 class="c1 c4" id="h.dil5ztfi2rbn">
    <span class="c3"
      >Derecho a Devoluci&oacute;n seg&uacute;n Ley del Consumidor</span
    >
  </h3>
  <p class="c1">
    <span class="c0"
      >De acuerdo con la Ley 19.496 sobre Protecci&oacute;n de los Derechos de
      los Consumidores de Chile, los usuarios tienen derecho a solicitar la
      devoluci&oacute;n del pago realizado a unAyuda.com por mejoras, servicios
      o funcionalidades adquiridas directamente dentro de la plataforma, dentro
      de las 24 horas siguientes al pago. Para ejercer este derecho, el usuario
      debe enviar una solicitud por correo electr&oacute;nico a
      soporte&#64;unAyuda.com dentro del plazo indicado. unAyuda.com
      procesar&aacute; la devoluci&oacute;n del pago dentro de los 5 d&iacute;as
      h&aacute;biles siguientes a la recepci&oacute;n de la solicitud.</span
    >
  </p>
  <p class="c1">
    <span class="c0"
      >Este derecho de devoluci&oacute;n no aplica a los pagos realizados
      directamente entre usuarios por la contrataci&oacute;n de servicios a
      trav&eacute;s de unAyuda.com. Las transacciones y acuerdos entre usuarios
      son responsabilidad exclusiva de las partes involucradas.</span
    >
  </p>
  <p class="c1 c5"><span class="c0"></span></p>
  <h3 class="c1 c4" id="h.4x828ts5yj95">
    <span class="c3">Modificaciones de los T&eacute;rminos y Condiciones </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >unAyuda.com se reserva el derecho de modificar estos t&eacute;rminos y
      condiciones en cualquier momento. Las modificaciones entrar&aacute;n en
      vigor inmediatamente despu&eacute;s de su publicaci&oacute;n en nuestro
      sitio web. Es responsabilidad del usuario revisar peri&oacute;dicamente
      los t&eacute;rminos y condiciones para estar al tanto de cualquier
      cambio.</span
    >
  </p>
  <h3 class="c1 c4" id="h.3x4wcjfa0t2z">
    <span class="c3">Ley Aplicable y Jurisdicci&oacute;n</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Estos t&eacute;rminos y condiciones se regir&aacute;n e
      interpretar&aacute;n de acuerdo con las leyes de la Rep&uacute;blica de
      Chile, independientemente del lugar de residencia del usuario. Cualquier
      disputa que surja en relaci&oacute;n con estos t&eacute;rminos y
      condiciones ser&aacute; sometida a la jurisdicci&oacute;n exclusiva de los
      tribunales de Santiago, Chile.</span
    >
  </p>
  <h3 class="c1 c4" id="h.9e3a7gru4yyc">
    <span class="c3">Resoluci&oacute;n de Conflictos </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >En caso de controversias derivadas del uso de unAyuda.com, los usuarios
      acuerdan someterse a un proceso de arbitraje antes de iniciar cualquier
      procedimiento judicial. El arbitraje se llevar&aacute; a cabo en Santiago,
      Chile, y las partes compartir&aacute;n los costos del
      &aacute;rbitro.</span
    >
  </p>
  <h3 class="c1 c4" id="h.z0b2ijr8j7pb">
    <span class="c3">Terminaci&oacute;n de la Cuenta</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >unAyuda.com se reserva el derecho de terminar o suspender su cuenta en
      cualquier momento, sin previo aviso, si se determina que ha violado estos
      t&eacute;rminos y condiciones o cualquier otra pol&iacute;tica de
      unAyuda.com.</span
    >
  </p>
  <p class="c1 c5"><span class="c0"></span></p>
  <h3 class="c1 c4" id="h.4a05w033ta1i">
    <span class="c3">Indemnizaci&oacute;n</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >El usuario se compromete a indemnizar y mantener indemne a unAyuda.com,
      sus directivos, empleados y agentes, de cualquier reclamaci&oacute;n,
      demanda, da&ntilde;os, perjuicios, o gastos, incluidos honorarios
      razonables de abogados, realizados por cualquier tercero debido a o como
      resultado de su uso del sitio web, su violaci&oacute;n de estos
      t&eacute;rminos y condiciones, o su violaci&oacute;n de cualquier ley o de
      los derechos de un tercero.</span
    >
  </p>
  <h3 class="c1 c4" id="h.i52u2f7udm0">
    <span class="c3">Separabilidad</span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Si alguna disposici&oacute;n de estos t&eacute;rminos y condiciones es
      considerada inv&aacute;lida o inaplicable por un tribunal de
      jurisdicci&oacute;n competente, las disposiciones restantes
      seguir&aacute;n en pleno vigor y efecto.</span
    >
  </p>
  <h3 class="c1 c4" id="h.2ea0bacr9e0g">
    <span class="c3">Aceptaci&oacute;n Electr&oacute;nica </span>
  </h3>
  <p class="c1">
    <span class="c0"
      >Al hacer clic en &quot;Aceptar&quot;, usted confirma que ha le&iacute;do,
      comprendido y aceptado todos los t&eacute;rminos y condiciones aqu&iacute;
      descritos. Esta aceptaci&oacute;n tiene la misma validez y efecto que una
      firma manuscrita, de conformidad con la Ley 19.799 sobre Documentos
      Electr&oacute;nicos, Firma Electr&oacute;nica y Servicios de
      Certificaci&oacute;n de Chile.</span
    >
  </p>
  <h3 class="c1 c4" id="h.34j1ra829b9r"><span class="c3">Contacto</span></h3>
  <p class="c1">
    <span class="c0"
      >Para cualquier consulta o comentario sobre estos t&eacute;rminos y
      condiciones, por favor, cont&aacute;ctenos a trav&eacute;s de nuestro
      sitio web en la secci&oacute;n de &quot;Contacto&quot; o mediante correo
      electr&oacute;nico a contacto&#64;unayuda.com.</span
    >
  </p>
  <p class="c1">
    <span class="c0"
      >unAyuda.com Conectando personas, facilitando servicios.</span
    >
  </p>
  <p class="c5 c8"><span class="c0"></span></p>
</div>
