import { Component,OnInit,NgModule,AfterViewInit} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { HeaderComponent } from '../../components/header/header.component';
import { FormsModule } from '@angular/forms'; // Importar FormsModule
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AlertaComponent } from '../../modals/alerta/alerta.component';
@Component({
  selector: 'app-editar-publicacion',
  standalone: true,
  imports: [HeaderComponent,FormsModule,ReactiveFormsModule],
  templateUrl: './editar-publicacion.component.html',
  styleUrl: './editar-publicacion.component.css'
})
export class EditarPublicacionComponent implements OnInit,AfterViewInit {
  publicacion: any;
  globalService : GlobalService;
  loading : boolean = false;
  foto : any;

  constructor(private globalServiceIn :GlobalService,private spinnerService: SpinnerService,
    private http: HttpClient,public dialog: MatDialog) {
   this.globalService = globalServiceIn;
   this.loading = true;
   this.spinnerService.show();
 
  }
  ngAfterViewInit(): void {
    this.loading = false;
    this.spinnerService.hide();
  }
  ngOnInit(): void {
    this.publicacion = this.globalService.getPublicacion();
    if(this.publicacion == undefined){
      window.location.href ="/publicacion";
    }
  }

  handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const foto : any =  input.files.item(0);
      this.foto = foto;
    }
  }
  isValid(): boolean{
    return true;
  }
  delete() {
    const dialogRef = this.dialog.open(AlertaComponent, {
      data: "Seguro de eliminar su publicación permanentemente? Podrá crear otra si lo desea.",
        height: '40%',
        width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.http.delete("/api/publicacion")
        .subscribe(response => {
          this.loading = false;
          this.spinnerService.hide();
          const dialogRef = this.dialog.open(AlertaComponent, {
            data: "Publicación eliminada",
              height: '40%',
              width: '400px'
          });
          dialogRef.afterClosed().subscribe(result => {
            window.location.href = "/";
          });
        }, error => {
          console.error('Error al cargar publicaciones:', error);
          this.loading = false;
          this.spinnerService.hide();
        });
      }

    });
  }
  submit() {
    if (this.isValid()) {
     this.loading = true;
     this.spinnerService.show();
     const formData: FormData = new FormData();
 
     if(this.foto){
      formData.append('foto', this.foto);
     }
     formData.append('id', this.publicacion.id);
     formData.append('nombre', this.publicacion.nombre);
     formData.append('descripcion', this.publicacion.descripcion);
     formData.append('valor',this.publicacion.valor);
     formData.append('facebook', this.publicacion.facebook);
     formData.append('instagram', this.publicacion.instagram);
     formData.append('whatsapp', this.publicacion.whatsapp);

     this.http.post<any>('/api/publicacion', formData).pipe(
       tap(
        (response) => {
          this.loading = false;
          this.spinnerService.hide();
          const dialogRef = this.dialog.open(AlertaComponent, {
            data: "Publicación guardada exitosamente",
              height: '40%',
              width: '400px'
          });
          dialogRef.afterClosed().subscribe(result => {
            window.location.href = "/";
          });
        },
        (error) => {
          console.error('Error:', error);
          this.loading = false;
          this.spinnerService.hide();
          const dialogRef = this.dialog.open(AlertaComponent, {
            data: "Ocurrio un problema al guardar publicación , "+((error.error.mensaje) ? error.error.mensaje : "Error desconocido"),
              height: '40%',
              width: '400px'
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      )
     ).subscribe();
    } else {
    console.log("Formulario no válido");
  }

 }
}