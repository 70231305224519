import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, map,catchError, tap, of } from 'rxjs';

import { SessionService } from './services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      return this.sessionService.getUser().subscribe(
        (response : any) => {
         if(response!= null && response.data !=null && response.data.principal != null){
              // Session OK
              return of(true);
         }else{
          return of(false);
         }
        },
        (error : any) => {
           // No hay usuario logeado
           const customUrl = `/login?error=auth`;
           window.location.href = customUrl;
           return of(false);
        }
      );
    }
}