import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private usuario: any;
  private publicacion: any;
  private tiposPublicacion: any;
  constructor() {}

  getPublicacion() {
    return this.publicacion;
  }

  setPublicacion(value: any) {
    this.publicacion = value;
  }

  getUsuario() {
    return this.usuario;
  }

  setUsuario(value: any) {
    this.usuario = value;
  }

  getTiposPublicacion() {
    return this.tiposPublicacion;
  }

  setTiposPublicacion(value: any) {
    this.tiposPublicacion = value.data;
  }
}
