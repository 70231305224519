<app-header></app-header>
<div  *ngIf=" aviso != undefined">
<div class="row featurette mt-2 mx-1 bg-light"  style="border-radius: 3rem; padding-bottom: 50px;">
   <div class="col-md-7 order-md-2">
      <div class="mt-5">
         <h3 style="font-size: 20px;"><i class="fa-solid fa-location-dot" style="color: darkcyan;"></i> {{aviso.ciudadNombre}} | {{aviso.tipoNombre}}</h3>
         <h2><i style="color: coral;" class="fa-solid fa-clock"></i> {{aviso.fechaCreacion}}</h2>
      </div>
      <h2 class="featurette-heading">
         {{aviso.nombre}} 
         <p *ngIf="aviso.whatsapp" class="mt-2">
            <span class="text-muted">
               <i class="fa-brands fa-whatsapp" style="color: teal;"></i>
                +{{aviso.whatsapp}}</span>
         </p>
         <div *ngIf="!aviso.whatsapp" class="mt-2">
            <h1 class="text-muted">
               <a style="color: darkgoldenrod;" href="/login">Inicia sesión</a> 
               para ver sus datos de contacto</h1>
         </div>
         <span class="text-muted"><i class="fa-solid fa-comments-dollar" style="color: goldenrod;"></i> ${{formatNumberWithCommas(aviso.valor)}}</span>
      </h2>
      <h1 *ngIf="aviso.email"><i class="fa fa-envelope" aria-hidden="true"></i>
         {{aviso.email}}
      </h1>
      <p class="lead mt-3 mb-5">{{aviso.descripcion}}</p>
      <a *ngIf="aviso.facebook" href='{{aviso.facebook}}' class="text-decoration-none" target="_blank"> 
      <i class="fa fa-facebook mx-2" aria-hidden="true" style="font-size: 4.0em;color:cornflowerblue;"></i> </a>
      <a  *ngIf="aviso.instagram" href='{{aviso.instagram}}' class="text-decoration-none" target="_blank"> 
      <i class="fa fa-instagram mx-2" aria-hidden="true" style="font-size: 4.0em;color:indianred"></i> </a>
      <div>
      </div>
   </div>
   <div class="col-md-5 order-md-1 mt-5">
      <img class="featurette-image img-fluid mx-auto" alt="500x500" src="{{aviso.foto}}" data-holder-rendered="true">
   </div>
</div>
<div class="mx-1 mt-5">
   <p>
      Ten mucho cuidado al contactarte con desconocidos, 
      si sospechas que esta publicación podría estar inflingiendo nuestros <a href="/terminos">Términos y condiciones</a> 
      por favor avisanos al email contacto&#64;unayuda.com .
   </p>
</div>
</div>
<!-- Footer-->
<footer class="footer bg-light" >
   <div class="container" >
      <div class="row" >
         <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
            <ul class="list-inline mb-2">
               <li class="list-inline-item"><a href="/terminos">Términos y condiciones</a></li>
               <li class="list-inline-item">⋅</li>
               <li class="list-inline-item"><a href="/privacidad">Política de privacidad</a></li>
            </ul>
            <p class="text-muted small mb-4 mb-lg-0">&copy; unAyuda.com 2024. Todos los derechos reservados.</p>
         </div>
         <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
            <ul class="list-inline mb-0">
               <li class="list-inline-item me-4">
                  <a href="#!"><i class="bi-facebook fs-3"></i></a>
               </li>
               <li class="list-inline-item me-4">
                  <a href="#!"><i class="bi-twitter fs-3"></i></a>
               </li>
               <li class="list-inline-item">
                  <a href="#!"><i class="bi-instagram fs-3"></i></a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</footer>